<template>
  <div class="page">
    <reservation-header
      :status="resData.id ? 'Saved' : 'New'"
      @save="saveData"
      @delete="deleteData"
      @saveAndLock="saveAndLock"
      @newNormal="createNormal"
      @newAdvance="createAdvance"
    ></reservation-header>
    <data-form2
      :key="resData['id'] + nextKey"
      :data="resData"
      :formFields="formFields"
      @changed="inputChanged"
      :readonly="true"
      minheight="1px"
      :nooverflow="true"
    />
    <form-futher :key="resData['id'] + 'foother'" v-if="resData" 
    :createdBy="resData['createdBy']"
    :created="resData['createdAt']"
    :updatedBy="resData['updatedBy']"
    :updated="resData['updatedAt']"
    />
  </div>
</template>

<script>
import DataForm2 from "../widgets/DataForm2.vue";
import FormFuther from "../users/FormFuther.vue";
import ReservationService from "../../services/reservation.service";
import StationsService from "../../services/stations.service";
import ReservationHeader from "./header/ReservationHeader";
import utils from "../../utils/common";

export default {
  name: "reservation-form",
  props: [],
  components: {
    DataForm2,
    ReservationHeader,
    FormFuther,
  },
  data() {
    return {
      message: "",
      reservation: null,
      nextKey: 0,
    };
  },
  computed: {
    formFields() {
      return {
        rows: [
          [{ id: { type: "hidden" } }],
          [
            { createdAt: { label: "Datum", type: "date" } },
            { voucher: { label: "Vaucher" } },
            { po_num: { label: "Po broj" } },
            { status: { 
              label: "Status",
              type: "dropdown",
                dropdownHeight: "150px",
                list: [
                  { id: "N", name: "New" },
                  { id: "NS", name: "No Show" },
                  { id: "CB", name: "Canceled booking" },
                  { id: "P", name: "Primero" },
                  { id: "F", name: "Finished" },
                  { id: "SR", name: "Storno rezervacija"}
                ],
             } },
          ],
          [
            {
              stations_id: {
                label: "Poslovnica",
                labelField: "code",
                type: "dropdown",
                empty: false,
                url: "/stations/byCode/OTB",
              },
            },
            {},
          ],
          [
            { driver_name: { label: "Vozač" } },
            { driver_on_invoice: { label: "Potrebno ime vozača na fakturi", type: 'checkbox' } },
          ],
          [
            {
              buyer_res: {
                label: "Firma",
                type: "dropdown",
                url: "/customers?limit=100000&ascending=1&activeOnly=1",
              },
            },{},
          ],
          [
            {
              countries_res: {
                label: "Država",
                multiple: false,
                type: "dropdown",
                url: "/countries?limit=100000&ascending=1",
              },
            },
            { city: { label: "Grad" } },
          ],
          [
            { check_out: { label: "Check out", type: "date" } },
            { check_in: { label: "Check in", type: "date" } },
          ],
          [{ reservation_num: { label: "Outbound Broj" } }, {}, {}],
          [
            {
              comment: {
                label: "Napomena",
                type: "textarray",
                rows: 4,
              },
            },
          ],

          // [{ divider: { type: "divider" } }],
        ],
      };
    },
    resData() {
      return this.reservation ?? {};
    },
    requiredFields() {
      return [
        "voucher",
        "driver_name",
        "buyer_res",
        "check_out",
        "check_in",
        "city",
        "countries_res",
        "reservation_num",
        "stations_id",
      ];
    },
  },
  methods: {
    inputChanged(e, name) {
      console.log({ e, name });
      this.resData[name] = e;

      if (
        (name == "check_in" || name == "check_out") &&
        this.resData.check_in < this.resData.check_out
      ) {
        this.$store.commit("app/showAlert", {
          type: "danger",
          message: `Check In datum mora da bude veći od Check Out datuma!`,
        });
      }
    },
    saveData() {
      // Validate
      if (
        !this.resData.voucher ||
        !this.resData.driver_name ||
        !this.resData.buyer_res ||
        !this.resData.check_out ||
        !this.resData.check_in ||
        !this.resData.city ||
        !this.resData.countries_res ||
        !this.resData.reservation_num ||
        !this.resData.stations_id
      ) {
        let required = [];
        for (const temp of this.requiredFields) {
          if (!this.resData[temp]) required.push(temp);
        }
        this.$store.commit("app/showAlert", {
          type: "danger",
          message: `Molimo vas da unesete sva obavezna polja! ${required}`,
        });
        return;
      }
      if (this.resData.check_in < this.resData.check_out) {
        this.$store.commit("app/showAlert", {
          type: "danger",
          message: `Check In datum mora da bude veći od Check Out datuma!`,
        });
        return;
      }
      if (this.resData && this.resData.id) {
        this.update(this.resData);
      } else {
        this.create(this.resData);
      }
    },
    cancel() {
      this.$store.commit("app/hideModal");
    },
    update(newData) {
      ReservationService.update(newData.id, newData)
        .then(() => {
          this.$store.commit("app/showAlert", {
            type: "success",
            message: "Podaci su promenjeni!",
          });
        })
        .catch((e) => {
          this.$store.commit("app/showAlert", {
            type: "danger",
            message: e,
          });
        });
    },
    create(newData) {
      ReservationService.create(newData)
        .then(() => {
          this.$store.commit("app/showAlert", {
            type: "success",
            message: "Outbound je kreiran!",
          });
          this.$router.go(-1);
        })
        .catch((e) => {
          this.$store.commit("app/showAlert", {
            type: "danger",
            message: e,
          });
        });
    },
    deleteData() {
      utils.showDialog(
        this.resData,
        "Da li ste sigurni da zelite da obrisete Outbound?",
        "Brisanje Outbounda: " + this.resData.id,
        () => {
          ReservationService.delete(this.resData.id)
            .then((response) => {
              this.$store.commit("app/showAlert", {
                type: "success",
                message: response.data.message,
              });
              this.$store.commit("app/hideModal");

              this.$router.go(-1);
            })
            .catch((e) => {
              console.log(e);
              this.$store.commit("app/showAlert", {
                type: "danger",
                message: e,
              });
            });
        }
      );
    },
  },
  async mounted() {
    if (this.$route.params.id != 0) {
      ReservationService.get(this.$route.params.id).then(async (res) => {
        this.reservation = res.data;
      });
    } else {
      StationsService.getByCode('OTB').then(async (res) => {
        this.reservation = {stations_id: res.data[0].id, createdAt : new Date().toISOString().substring(0, 10), driver_on_invoice: true, status: 'N'};
      });
    }
  },
  created() {},
  watch: {},
};
</script>


<style scoped>
.page {
  max-width: 1200px;
  margin-left: 30px;
}
.company {
  font-size: smaller;
}

/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>